import React, { useEffect, useState, useRef } from "react";

/** Helpers */
import clsx from "clsx";
import unionBy from "lodash/unionBy";
import uniqBy from "lodash/uniqBy";
import { useTranslation } from "react-i18next";
import useHelper from "@/src/_common/useHelper";
import { match, parse } from "@/src/_utilites/autosuggest-highlight";
import { useDebounce } from "use-debounce";

/** Nextjs */
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

/** MUI */
import { ClickAwayListener } from "@mui/base";
import {
    alpha,
    Popover,
    Box,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
/** - icons */
import { Close, Search } from "@mui/icons-material";

/** Redux */
import { useDispatch } from "react-redux";
import { setTableOrChart } from "@/src/_appStore/DB_Common";
import { setDatesSelected } from "@/src/_appStore/DB_Dates";
import * as ChartsFS from "@/src/_appStore/DB_Filters_Charts";
import * as TableFS from "@/src/_appStore/DB_Filters_Table";
import { setSelectedIndicators } from "@/src/_appStore/DB_Indicators";
import { setSelectedTerritorialUnitsItem } from "@/src/_appStore/DB_JTs";
import * as TableLists from "@/src/_appStore/DB_Lists_Table";

/** API */
import { getSearchBarResults } from "@/src/_APIs";

/** UI */
const AppTooltip = dynamic(() => import("@/src/_common/AppTooltip"));

const useStyles = makeStyles((theme) => ({
    searchBarWrapper: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#F8F9FA",
        [theme.breakpoints.between("md", "xl")]: {
            minHeight: 230,
            marginBottom: theme.spacing(3),
        },
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0, 1),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 1),
        },
    },

    searchBarWrapperForMenu: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        [theme.breakpoints.between("md", "xl")]: {
            marginBottom: theme.spacing(0),
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0, 1),
        },
    },

    searchBarWrapperForMobileMenu: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "transparent",
        [theme.breakpoints.between("md", "xl")]: {
            marginBottom: theme.spacing(0),
        },
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0),
        },
    },
    paper: {
        ...theme.shape.boxedWrapper,
        flexGrow: 1,
        margin: theme.spacing(6, 0),
        padding: "22px 25px",
        border: "none",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2, 2),
            margin: theme.spacing(2, 0),
        },
    },
    paperForMenu: {
        margin: theme.spacing(2, 0, 1, 0),
    },
    paperForMobileMenu: {
        margin: theme.spacing(0, 0, 0, 0),
        borderRadius: theme.spacing(0, 0, 1, 1),
        boxShadow: "none",
    },
    textField: {
        backgroundColor: "#FFFFFF",
        marginTop: 0,
        marginBottom: 0,
        "& label": {
            '&[data-shrink="true"]': {},
            '&[data-shrink="false"]': {
                opacity: 0.5,
                marginLeft: 40,
                top: -2,
                // top: 0,
                fontSize: "1.11111111rem",
                [theme.breakpoints.down("md")]: {
                    marginLeft: 40,
                    fontSize: "1.11111111rem",
                    marginTop: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    marginLeft: 40,
                    fontSize: "1.11111111rem",
                    marginTop: 0,
                },
            },
            [theme.breakpoints.down("md")]: {
                marginLeft: 18,
            },
            [theme.breakpoints.down("md")]: {},
        },
    },
    textFieldFocus: {
        "& label": {
            marginLeft: 0,
        },
    },
    label: {
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        // maxWidth: "90%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        // padding: "10.18px 14px",
    },

    popoverPaper: {
        ...theme.shape.boxedWrapper,
        borderRadius: 4,
        marginTop: theme.spacing(-3),
        // minHeight: 400,
        // maxHeight: "50vh",
        // height: "100%",
        height: "auto",
        position: "absolute",
        zIndex: 1300,

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 0),
        },
    },
    popoverList: {
        listStyleType: "none",
        width: "100%",
        // height: "100%",
        height: "auto",
        maxHeight: 400,
        overflow: "hidden",
        overflowY: "auto",
        paddingLeft: 0,
        margin: 0,
    },
    propperName: {
        color: "rgba(0,0,0,0.87)",
        cursor: "pointer",
        fontSize: "1rem",
        fontWeight: 400,
        fontStyle: "normal",
        letterSpacing: "normal",
        marginRight: 5,
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.06)",
        },
        [theme.breakpoints.down("xl")]: {
            fontSize: "0.88888889rem",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "0.88888889rem",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.77777778rem",
        },
    },
    unHighlighted: {
        fontWeight: 400,
    },
    // highlighted: {
    //     fontWeight: 700,
    // },

    highlighted: {
        backgroundColor: alpha(theme.palette.primary.light, 0.99),
        color: "#fff",
    },
    truncated: {
        whiteSpace: "nowrap",
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        [theme.breakpoints.down("md")]: {
            overflow: "unset",
        },
    },
    searchEl: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        color: "inherit",
        textDecoration: "none",
        "&:hover": {
            // textDecoration: "underline",
        },
        "& > *": {
            display: "inline-flex",
        },
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    searchElTextWrap: {
        maxWidth: "75%",
        flexGrow: 1,
        [theme.breakpoints.down("sm")]: {
            opacity: 1,
            maxWidth: "unset",
            display: "block",
            width: "100%",
            "& > span": {
                opacity: 1,
            },
        },
    },
    searchElText: {
        maxWidth: "40%",
    },
    searchElSource: {
        marginLeft: theme.spacing(1),
    },
    searchElType: {
        display: "block",
        justifySelf: "flex-end",
        justifyContent: "flex-end",
        maxWidth: "20%",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            maxWidth: "unset",
            display: "block",
            width: "100%",
            textAlign: "left",
            borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
            paddingBottom: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            width: 64,
        },
    },
}));

const MainSearchbar = (props) => {
    const { fromTopMenu, fromMobileMenu, autoFocus } = props;

    // console.log(` ----------------------------`);
    // console.log(`MainSearchbar ~ props:`, props);
    // console.log(` ----------------------------`);

    const { t, i18n } = useTranslation();
    const { translationFromJson } = useHelper();
    const classes = useStyles();
    const router = useRouter();
    const dispatch = useDispatch();

    const focusTrap = useRef();
    const textFieldRef = useRef();
    const inputRef = useRef();
    const abortControllerRef = useRef();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    /** Component states */
    const [ERROR, setERROR] = useState(false);
    const [FOCUSED, setFOCUSED] = useState(false);
    const [LOADING, setLOADING] = useState(false);
    const [OPTIONS, setOPTIONS] = useState([]);
    const [OPTIONS_FILTERED, setOPTIONS_FILTERED] = useState([]);
    const [PASTED, setPASTED] = useState(false);
    const [SEARCH_TEXT, setSEARCH_TEXT] = useState("");
    const [SORT, setSORT] = useState(false);
    const [TYPES, setTYPES] = useState([]);
    const [SHRINK, setSHRINK] = useState(false);
    const [WIDTH, setWIDTH] = useState(1024);
    /** - debounce */
    const [searchText] = useDebounce(SEARCH_TEXT, 1000);

    /** Handlers */
    const handleClear = () => {
        setSEARCH_TEXT("");
        setOPTIONS([]);
        setFOCUSED(true);
        setERROR(false);
        setSHRINK(false);
        inputRef?.current?.focus();
    };

    const handleClearCustomerFilters = () => {
        /** Clear indicators */
        dispatch(setSelectedIndicators([]));

        /** Reset view to 'Table' */
        dispatch(setTableOrChart("table"));

        /** Clear JTs */
        dispatch(setSelectedTerritorialUnitsItem([]));

        /** Clear Dates */
        dispatch(setDatesSelected([]));

        /** Clear table filters */
        dispatch(TableFS.clear());

        /** Clear table Lists */
        dispatch(TableLists.clear());

        /** Clear charts filters */
        dispatch(ChartsFS.clear());
    };

    const handleInputChange = (event) => {
        const _value = event.target?.value;

        if (event && event.nativeEvent && event.nativeEvent.inputType === "insertFromPaste") {
            setPASTED(true);
        }

        setSEARCH_TEXT(_value);
    };

    const handleBlur = () => {
        setFOCUSED(false);
        // setSEARCH_TEXT("");
        // setOPTIONS([]);
        setERROR(false);
        setSHRINK(!!SEARCH_TEXT);
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        if (SEARCH_TEXT) {
            router.push({
                pathname: `/${i18n?.language}/search-results`,
                query: { search: SEARCH_TEXT },
            });
        }
    };

    /** Effects */
    /** - watch router query search */
    useEffect(() => {
        if (SEARCH_TEXT) return;
        const searchText = router?.query?.search || "";
        setSHRINK(!!searchText);
        setSEARCH_TEXT(searchText);
    }, []);

    /** - get results from API */
    useEffect(() => {
        // clean up controller
        let isSubscribed = true;

        if (searchText?.length >= 3) {
            setERROR(false);
            setLOADING(true);

            abortControllerRef.current?.abort();

            abortControllerRef.current = new AbortController();

            getSearchBarResults({
                searchText: searchText,
                isPl: i18n.language === "pl",
                typ_id: 0,
                signal: abortControllerRef.current?.signal,
            })
                .then((result) => {
                    if (result.message && result.message.type === "Error") {
                        isSubscribed &&
                            setERROR({
                                message: {
                                    text: "Brak rezultatów",
                                    text_en: "No results",
                                },
                            });
                        isSubscribed && setOPTIONS([]);
                    } else {
                        if (result instanceof Array) {
                            const cleaned = result.map((item) => {
                                return {
                                    ...item,
                                    nazwa: (item.name || item.nazwa)?.replace(/<(.|\n)*?>|\n|\t/gm, ""),
                                    nazwa_ang: (item.name_en || item.nazwa_ang)?.replace(/<(.|\n)*?>|\n|\t/gm, ""),
                                    name: (item.name || item.nazwa)?.replace(/<(.|\n)*?>|\n|\t/gm, ""),
                                    name_en: (item.name_en || item.nazwa_ang)?.replace(/<(.|\n)*?>|\n|\t/gm, ""),
                                };
                            });
                            const _sorted = [...cleaned];
                            isSubscribed && setOPTIONS(_sorted);
                        }
                    }
                })
                .catch((error) => {
                    console.log("%c searchByStringFromAPI error: ", "background: #F00; color:#FFF", error);
                })
                .finally(() => {
                    isSubscribed && setLOADING(false);
                });
        }

        // cancel subscription to useEffect
        return () => (isSubscribed = false);
    }, [
        // SEARCH_TEXT
        searchText,
    ]);

    /** - filter OPTIONS */
    useEffect(() => {
        // clean up controller
        let isSubscribed = true;

        const _types = uniqBy(
            OPTIONS?.map((o) => {
                const _active = TYPES?.find((t) => t.id === o.type.id)?.active;
                const _count = OPTIONS?.filter((_o) => _o.type.id === o.type.id)?.length;
                return {
                    ...o.type,
                    active: _active !== undefined ? _active : true,
                    count: _count,
                };
            }),
            "id"
        );

        const matchWord = SEARCH_TEXT?.toLowerCase();

        const _options_filtered = OPTIONS?.filter((opt) =>
            _types
                ?.filter((t) => t.active)
                ?.map((t) => t.id)
                ?.includes(opt.type.id)
        );

        const _sorted = SORT
            ? [..._options_filtered]
                  .map((o) => [o.name.toLowerCase(), o])
                  .filter(([name]) => name.includes(matchWord))
                  .sort(([a], [b]) => a.indexOf(matchWord) - b.indexOf(matchWord))
                  .map(([, o]) => o)
            : _options_filtered;

        if (isSubscribed && JSON.stringify(TYPES) !== JSON.stringify(_types)) {
            setTYPES(_types);
        }

        setOPTIONS_FILTERED(_sorted);

        // setFOCUSED(!!_types?.length);

        return () => (isSubscribed = false);
    }, [OPTIONS, TYPES, SORT, SEARCH_TEXT]);

    /** - set WIDTH */
    useEffect(() => {
        const setWidth = () => setWIDTH(textFieldRef?.current?.clientWidth || 1024);
        setWidth();

        window.addEventListener("resize", setWidth);
        return () => window.removeEventListener("resize", setWidth);
    }, [textFieldRef.current]);

    /** - autoFocus */
    useEffect(() => {
        if (!inputRef?.current) return;
        if (autoFocus) {
            inputRef?.current?.focus();
        }
    }, [autoFocus, inputRef]);

    const OptionItem = (props) => {
        const { option, parts, index } = props;

        const _title = (
            <Box key={option.id} sx={{ py: 2, px: 3, overflow: "hidden", backgroundColor: "transparent!important" }}>
                <Typography variant={"body1"} sx={{ fontSize: "1.1111111rem", mb: 2, backgroundColor: "inherit" }}>
                    {parts.map((part, index) => (
                        <span
                            key={`${part.text}-${index}`}
                            className={clsx(part.highlight ? classes.highlighted : classes.unHighlighted)}
                        >
                            {part.text}
                        </span>
                    ))}
                </Typography>

                <Typography variant={"body2"} sx={{ borderTop: "1px solid #EEE" }}>
                    <em>
                        {t("Źródło")}
                        {":"}&nbsp;
                        <span>{`${translationFromJson(option.source, "name")} (${translationFromJson(
                            option.type,
                            "name"
                        )})`}</span>
                    </em>
                </Typography>
            </Box>
        );
        return (
            <Box
                className={classes.propperName}
                key={option.id}
                component={"li"}
                role={"menuitem"}
                aria-roledescription=" "
            >
                <AppTooltip
                    title={_title || ""}
                    arrow
                    placement={"top-start"}
                    type={"dark"}
                    sx={{ width: "100%" }}
                    disableFocusListener
                >
                    <Box
                        className={classes.searchEl}
                        component={"a"}
                        href={`/${i18n?.language}/search-results?search=${translationFromJson(option, "name")}&typ_id=${option.type?.id}`}
                        onClick={handleClearCustomerFilters}
                        // aria-label={translationFromJson(option, "name")}
                        role="none"
                        aria-roledescription={" "}
                        aria-label={`${translationFromJson(option, "name")} - ${t("Typ")}: ${translationFromJson(
                            option.type,
                            "name"
                        )}; ${t("Link {{number}} z {{count}} do wyników wyszukiwania", {
                            number: index + 1,
                            count: OPTIONS_FILTERED?.length,
                            dot: ".",
                        })};  `}
                        ref={(ref) => {
                            if (ref && index === 0) {
                                setTimeout(() => {
                                    ref?.focus();
                                }, 1000);
                            }
                        }}
                    >
                        <span className={classes.searchElTextWrap} aria-hidden={true}>
                            <span
                                className={clsx(classes.searchElText, classes.truncated)}
                                style={{ maxWidth: option.source?.name ? "50%" : "99%" }}
                            >
                                {parts.map((part, index) => (
                                    <span
                                        key={`${part.text}-${index}`}
                                        className={clsx(part.highlight ? classes.highlighted : classes.unHighlighted)}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </span>

                            {option.source?.name ? (
                                <span className={clsx(classes.searchElSource, classes.truncated)}>
                                    {` > ${translationFromJson(option.source, "name")}`}
                                </span>
                            ) : (
                                <span />
                            )}
                        </span>
                        <span className={clsx(classes.searchElType, classes.truncated)} aria-hidden={true}>
                            {translationFromJson(option.type, "name")}
                        </span>
                    </Box>
                </AppTooltip>
            </Box>
        );
    };

    return (
        <Container
            maxWidth={false}
            className={clsx(
                fromTopMenu
                    ? fromMobileMenu
                        ? classes.searchBarWrapperForMobileMenu
                        : classes.searchBarWrapperForMenu
                    : classes.searchBarWrapper
            )}
        >
            <Container maxWidth={"xl"} disableGutters style={{ maxWidth: 1530 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <ClickAwayListener onClickAway={handleBlur}>
                            <Paper
                                className={clsx(
                                    classes.paper,
                                    fromTopMenu && classes.paperForMenu,
                                    fromMobileMenu && classes.paperForMobileMenu
                                )}
                                elevation={0}
                            >
                                {/* <pre>{JSON.stringify({ WIDTH })}</pre> */}
                                <form onSubmit={handleSubmit}>
                                    <TextField
                                        ref={textFieldRef}
                                        fullWidth
                                        label={<>{isMobile ? t(`MainSearchLabelShort`) : t(`MainSearchLabel`)}</>}
                                        value={SEARCH_TEXT}
                                        onChange={handleInputChange}
                                        className={clsx(classes.textField, classes.margin)}
                                        inputRef={inputRef}
                                        InputLabelProps={{ shrink: SHRINK }}
                                        InputProps={{
                                            classes: { input: classes.label },
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    disablePointerEvents={true}
                                                    sx={{ px: 1 }}
                                                >
                                                    {!!LOADING ? (
                                                        <CircularProgress size={20} color={"inherit"} />
                                                    ) : (
                                                        <Search
                                                            color={"disabled"}
                                                            sx={{
                                                                width: 24,
                                                                height: 24,
                                                                fontSize: "1rem",
                                                                color: "#00000099",
                                                            }}
                                                        />
                                                    )}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {SEARCH_TEXT ? (
                                                        <IconButton
                                                            size={"small"}
                                                            onClick={handleClear}
                                                            aria-label={t("Wyczyść")}
                                                            disabled={!SEARCH_TEXT?.length}
                                                        >
                                                            <Close
                                                                fontSize={"small"}
                                                                color={SEARCH_TEXT?.length ? "primary" : "disabled"}
                                                            />
                                                        </IconButton>
                                                    ) : null}
                                                </InputAdornment>
                                            ),
                                        }}
                                        onFocus={(e) => {
                                            setSHRINK(true);
                                            setFOCUSED(true);
                                        }}
                                        onBlur={() => {
                                            setSHRINK(!!SEARCH_TEXT);
                                        }}
                                        helperText={
                                            ERROR ? (
                                                <>{translationFromJson(ERROR?.message, "text")}</>
                                            ) : SHRINK && SEARCH_TEXT?.length < 3 ? (
                                                t("Po wpisaniu trzech znaków, pojawi się lista z podpowiedziami")
                                            ) : // ) : null
                                            LOADING ? (
                                                t("Trwa wyszukiwanie dla {{SEARCH_TEXT}}", { SEARCH_TEXT })
                                            ) : OPTIONS_FILTERED?.length ? (
                                                t("Znaleziono {{count}} wyników", { count: OPTIONS_FILTERED?.length })
                                            ) : null
                                        }
                                        error={!!ERROR}
                                        name="main-search-bar-input"
                                        id="main_search_bar_input"
                                    />
                                </form>
                                <Paper
                                    className={classes.popoverPaper}
                                    sx={{
                                        width: "100%",
                                        maxWidth: WIDTH, // textFieldRef?.current?.clientWidth || 1024,
                                        display: !!FOCUSED && OPTIONS_FILTERED?.length ? "block" : "none",
                                    }}
                                    role={"section"}
                                    aria-label={" "}
                                    aria-expanded={!!FOCUSED && OPTIONS_FILTERED?.length}
                                >
                                    <Box
                                        className={classes.popoverList}
                                        component={"ul"}
                                        role={"menu"}
                                        aria-roledescription={` `}
                                    >
                                        {OPTIONS_FILTERED?.map((option, index) => {
                                            const matches = unionBy(
                                                match(translationFromJson(option, "name"), SEARCH_TEXT, false),
                                                JSON.stringify
                                            );
                                            const parts = parse(translationFromJson(option, "name"), matches);
                                            return (
                                                <OptionItem
                                                    option={option}
                                                    parts={parts}
                                                    key={option.id}
                                                    index={index}
                                                />
                                            );
                                        })}
                                    </Box>
                                </Paper>
                            </Paper>
                        </ClickAwayListener>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default MainSearchbar;
